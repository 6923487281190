import {CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import {MaterialModule} from './material.module';
import {CustomErrorHandlerService} from './_services/customErrorHandler.service';
import {HttpInterceptorService} from './_services/http-interceptor.service';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { LayoutModule } from 'app/layout/layout.module';
import {SharedModule} from './shared/shared.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';

import {ToastrModule} from 'ngx-toastr';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {InputTrimModule} from 'ng2-trim-directive';
import {AvatarModule} from 'ngx-avatar';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {AngularSplitModule} from 'angular-split';
const routerConfig: ExtraOptions = {
    preloadingStrategy       : PreloadAllModules,
    scrollPositionRestoration: 'enabled'
};

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        NgxMatSelectSearchModule,
        NgMultiSelectDropDownModule.forRoot(),
        ToastrModule.forRoot({
          timeOut: 8000, progressBar: true, enableHtml: true,
          closeButton: true, progressAnimation: 'increasing',
          preventDuplicates: true,
        }),
        AngularSplitModule,
        AvatarModule,
        InputTrimModule,
        DragDropModule,
        SharedModule,
        // Core module of your application
        CoreModule,
        // Material module
        MaterialModule,
        SharedModule,

        // Layout module of your application
        LayoutModule,

        // 3rd party modules that require global configuration via forRoot
        MarkdownModule.forRoot({})
    ],
    bootstrap   : [
        AppComponent
    ],
    providers: [
        {provide: ErrorHandler, useClass: CustomErrorHandlerService},
        {provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true }
    ],
    schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule
{
}
