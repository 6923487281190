import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, RoutesRecognized} from '@angular/router';
import {CommonService} from './_services/common.services';
import {AuthenticationService} from './_services/authentication.service';

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})
export class AppComponent  implements OnInit
{
    /**
     * Constructor
     */
    constructor(private authService: AuthenticationService, private router: Router,
               private route: ActivatedRoute, public cs: CommonService)
    {
        this.authService.getSessionSettings();
    }
    ngOnInit(): void {
    // @ts-ignore
    /* this.router.events.subscribe((event: any) => {
      if (!!event && event instanceof RoutesRecognized) {
        const params = event.state.root.firstChild.queryParams;
        if (params.userName && params.otp) {
          localStorage.setItem('userName', params.userName );
          localStorage.setItem('otp', params.otp );
        }
      }
    }); */
  }
}
