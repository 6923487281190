import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {BehaviorSubject, Subject} from 'rxjs';
import {BaseRequestService} from './base.service';
import {CommonService} from './common.services';
import {LoaderService} from './loader.service';
import {MyToastrService} from './toastr.service';
import {HttpHeaders} from '@angular/common/http';

@Injectable({providedIn: 'root'})

export class AuthenticationService {
    public isAuthenticated = false;
    currentUser: any;
    constructor(readonly router: Router, private cs: CommonService,
                public baseRequestService: BaseRequestService,
                readonly location: Location,
    ) {
    }

    getSessionSettings(): void {
        const token = localStorage.getItem('app-token');
        if (token && token.length) {
            this.baseRequestService.authHeader = new HttpHeaders({'Content-Type': 'application/json',
             'X-Authorization': `Bearer ${token}`});
            this.isAuthenticated = true;
            this.router.navigateByUrl('/manage');
        }
    }

    logout(): void {
        this.isAuthenticated = false;
        this.cs.isExecutiveDashboard = false;
        localStorage.clear();
        this.baseRequestService.doRequest(this.baseRequestService.API + '/api/auth/logout', 'post', null, null,
         this.baseRequestService.authHeader).subscribe((res: any) => {
            if (res) {
                this.router.navigateByUrl('/sign-in');
            }
        });
    }
}
