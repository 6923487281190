<div *ngIf="listOfFormElements && listOfFormElements.length">
  <form [formGroup]="form" novalidate="off">
    <div *ngFor="let formControl of listOfFormElements" class="form-row">
      <ng-container *ngIf="formControl.type !== 'companypicker' &&  formControl.type !== 'agentpicker'">
         <app-dynamic-control [outputValue]="Valuesoutput" [formElement]="formControl" [form]="form"
      (valChange)="fieldValChange($event)"></app-dynamic-control>
      </ng-container>
    </div>
    <div class="form-row">
      <button type="submit" id="Savebtn" *ngIf="isSaveBtn" [disabled]="!form || !form.valid" class="mr-2" mat-raised-button color="primary"
              (click)="Save(form)">{{fnBtnName}}
      </button>
      <button type="submit" id="Closebtn" (click)="Cancel()" *ngIf="isCloseBtn" mat-raised-button>{{closeBtnName}}</button>
    </div>
  </form>
</div>
