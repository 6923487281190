import { Component, Input, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-tank',
  templateUrl: './tank.component.html',
  styleUrls: ['./tank.component.scss']
})
export class TankComponent implements OnInit, AfterViewInit {
  @Input() level: any;
  @Input() name: any;
  @Input() product: any;

  constructor() {

  }
    ngAfterViewInit(): void {
        const cnt = document.getElementById(this.name);
        const water = document.getElementById(this.product);
        cnt.innerHTML = this.level;
        water.style.transform='translate(0'+','+(100 - +this.level)+'%)';
    }

  ngOnInit(): void {
  }

}
