import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output, SimpleChange,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {FormControlService} from 'app/_services/form-control.service';
import {FormControl, FormGroup} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {MatSelect} from '@angular/material/select';
import {ReplaySubject, Subject} from 'rxjs';
import {AuthenticationService} from '../../../_services/authentication.service';
import {LoaderService} from '../../../_services/loader.service';
import {BaseRequestService} from '../../../_services/base.service';
import {MyToastrService} from '../../../_services/toastr.service';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  providers: [FormControlService]
})

export class DynamicFormComponent implements OnInit, OnDestroy {
  @Input() fnBtnName = 'Save';
  @Input() closeBtnName = 'Close';
  @Input() isCloseBtn = true;
  @Input() isSaveBtn = true;
  @Input() listOfFormElements: any = [];
  @Output() saveCallBack = new EventEmitter();
  @Output() cancelCallBack = new EventEmitter();
  // @Input() selectedValue: any;
  @Output() valueUpdate = new EventEmitter();
  form!: FormGroup;
  @Input() Valuesoutput: any = {};
  @Output() outputValue: any = {};
  payLoad = '';
  @ViewChild('companySelect', {static: true}) companySelect!: MatSelect;
  public companyCtrl: FormControl = new FormControl();
  public companyFilterCtrl: FormControl = new FormControl();
  public filteredCompanies: ReplaySubject<any> = new ReplaySubject<any>(1);
  public searching = false;
  protected onDestroySearch = new Subject<void>();
  agentList: any = [];
  company: any;
  companies: any;
  companyHash: any = {};
  allComp: any;
  agentEnum = ['', 'Probe', 'Lightweight Agent', 'Lightweight Agent Installed', 'External Scan Agent'];
  constructor(private formService: FormControlService, public toastr: ToastrService,
              public authService: AuthenticationService, private loaderService: LoaderService,
              private baseService: BaseRequestService, private toast: MyToastrService) {
  }

  ngOnInit(): void {
    this.form = this.formService.toFormGroup(this.listOfFormElements);
  }

  Save(form: any): void {
    const frmValues = form.value;
    if (frmValues.netaName && !frmValues.netaName.replace(/\s/g, '').length) {
      this.toastr.error('Input cannot contain only whitespaces');
    } else {
      this.saveCallBack.emit(frmValues);
    }
  }

  Cancel(): void {
    this.cancelCallBack.emit();
  }

  fieldValChange($event: any): void {
    this.valueUpdate.emit($event);
  }

  onSubmit(): void {
    // this.form.value["SelectedDropdown"] = this.selectedValue.value;
    // delete  this.form.value.undefined;
    // @ts-ignore
    this.payLoad = JSON.stringify(this.form.value);
  }

  ngOnDestroy(): void {
    this.onDestroySearch.next();
    this.onDestroySearch.complete();
  }
}
