import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {BaseRequestService} from './base.service';
import {MyToastrService} from './toastr.service';

@Injectable({providedIn: 'root'})
export class CommonService {
    cHash: any;
  isExecutiveDashboard = false;
  isCurrentStadiumChanged = false;
  currentStadiumClose: Subject<any> = new Subject<any>();
  currentStadiumChange: Subject<any> = new Subject<any>();
  previousLink = '';
  companiesList: any = [];
  modifiedDiscoverItems: any;
  patchingStatus = false;
  isEulaAccepted = false;
    // eslint-disable-next-line @typescript-eslint/naming-convention
  Objectkeys = Object.keys;
  complianceMaster: any = {};
  complianceList: any = [];
  securityMatrix: any = {};
  complianceMatrix: any = {};
  angularEditorChange: Subject<object> = new Subject<object>();
  bwListChange: Subject<object> = new Subject<object>();
  captureScreenChange: Subject<object> = new Subject<object>();
  initCaptureChange: Subject<object> = new Subject<object>();
  openSideNavChange: Subject<object> = new Subject<object>();
  selectedSiteChange: Subject<any> = new Subject<any>();
  rmRefreshEvent: Subject<any> = new Subject<any>();
  selectedNode = {};
  networkDeviceReloadChange: Subject<any> = new Subject<any>();
  addCompanyChange: Subject<any> = new Subject<any>();
  addSiteChange: Subject<any> = new Subject<any>();
  checkNode = {};
  timeZoneList: any;
  currentTimezone: any;
  unauth: any;
  currentCompany: any;
  ccId: any;
  modalHolder: string[] = [];
  currentAssessment: any;
  currentCompanyView: string;
  eulaContent = '';
  // Saudi Arabia
  flags: any = [
    { name: 'No Match', code: 'NA', svg: 'na.svg'},
    {name: 'Argentina', code: 'AR', svg: 'ar.svg'},
    {name: 'Australia', code: 'AU', svg: 'au.svg'},
    {name: 'SaudiArabia', code: 'SA0', svg: 'sa0.png'},
    {name: 'Egypt', code: 'EGP', svg: 'egp.png'},
    {name: 'Belgium', code: 'BE', svg: 'be.svg'},
    {name: 'Brazil', code: 'BR', svg: 'br.svg'},
    {name: 'Canada', code: 'CA', svg: 'ca.svg'},
    {name: 'Cameroon', code: 'CM', svg: 'cm.svg'},
    {name: 'Costa Rica', code: 'CR', svg: 'ar.svg'},
    {name: 'Croatia', code: 'HR', svg: 'hr.svg'},
    {name: 'Ecuador', code: 'EC', svg: 'ec.svg'},
    {name: 'England', code: 'GB-ENG', svg: 'gb-eng.svg'},
    {name: 'Denmark', code: 'DK', svg: 'dk.svg'},
    {name: 'France', code: 'FR', svg: 'fr.svg'},
    {name: 'Germany', code: 'DE', svg: 'de.svg'},
    {name: 'Ghana', code: 'GH', svg: 'gh.svg'},
    {name: 'Iran', code: 'IR', svg: 'ar.svg'},
    {name: 'Japan', code: 'JP', svg: 'jp.svg'},
    {name: 'Mexico', code: 'MX', svg: 'mx.svg'},
    {name: 'Morocco', code: 'MA', svg: 'ma.svg'},
    {name: 'Netherlands', code: 'NL', svg: 'nl.svg'},
    {name: 'Poland', code: 'PL', svg: 'pl.svg'},
    {name: 'Portugal', code: 'PT', svg: 'pt.svg'},
    {name: 'Qatar', code: 'QA', svg: 'qa.svg'},
    {name: 'Saudi Arabia', code: 'SA', svg: 'sa.svg'},
    {name: 'Senegal', code: 'SN', svg: 'sn.svg'},
    {name: 'Serbia', code: 'CS', svg: 'cs.svg'},
    {name: 'South Korea', code: 'KR', svg: 'kr.svg'},
    {name: 'Spain', code: 'ES', svg: 'es.svg'},
    {name: 'Switzerland', code: 'CH', svg: 'ch.svg'},
    {name: 'Tunisia', code: 'TN', svg: 'tn.svg'},
    {name: 'United States', code: 'US', svg: 'us.svg'},
    {name: 'Uruguay', code: 'UY', svg: 'uy.svg'},
    {name: 'Wales', code: 'GB-WLS', svg: 'gb-wls.svg'},
  ];
  constructor(public baseRequestService: BaseRequestService,
              private toast: MyToastrService) {
  }

  isPrivateIP(ip: string): boolean {
    const parts = ip.split('.');
    return parts[0] === '10' ||
      (parts[0] === '172' && (parseInt(parts[1], 10) >= 16 && parseInt(parts[1], 10) <= 31)) ||
      (parts[0] === '192' && parts[1] === '168');
  }

  isZeroOctetIP(ip: string): boolean {
    const parts = ip.split('.');
    if (parts.length !== 4) {
      return true;
    }
    return (parts[3] === '0');
  }

  isUuid(uuid: string): boolean {
    if (uuid) {
      // @ts-ignore
      return ( uuid.match(/^[0-9A-Fa-f]{8}(?:-[0-9A-Fa-f]{4}){3}-[0-9A-Fa-f]{12}$/) ?.length > 0 );
    } else {
      return false;
    }
  }

  nl2br(str: string): string{
   return str.replace(/(?:\r\n|\r|\n)/g, '<br>');
  }

  compare(a: number | string, b: number | string, isAsc: boolean): any {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  buildQuery(data: { [x: string]: any }): any {
    const baseQuery = {query: {bool: {must: []}}};
    this.Objectkeys(data).forEach((obj) => {
      const tmpObj = {match: {}};
      // @ts-ignore
      tmpObj.match[obj] = data[obj];
      // @ts-ignore
      baseQuery.query.bool.must.push(tmpObj);
    });
    return baseQuery;
  }
  onlyUnique(value: any, index: any, self: string | any[]): boolean {
      return self.indexOf(value) === index;
  }
  reloadDevice(deviceId: any): void {
    this.networkDeviceReloadChange.next(deviceId);
  }

  percentCalculator(x: number, y: number): number {
    return Math.floor((y / x) * 100);
  }

  copyClip(cmd: string): void {
    const el = document.createElement('textarea');
    el.value = cmd;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.toast.sToast('success', 'Copied to clipboard');
  }

  getTimeZone(): void {
    const offset = (new Date()).getTimezoneOffset();
    const timezones = {
    '-12': 'Pacific/Kwajalein',
    '-11': 'Pacific/Samoa',
    '-10': 'Pacific/Honolulu',
    '-9': 'America/Juneau',
    '-8': 'America/Los_Angeles',
    '-7': 'America/Denver',
    '-6': 'America/Mexico_City',
    '-5': 'America/New_York',
    '-4': 'America/Caracas',
    '-3.5': 'America/St_Johns',
    '-3': 'America/Argentina/Buenos_Aires',
    '-2': 'Atlantic/Azores',
    '-1': 'Atlantic/Azores',
    '0': 'Europe/London',
    '1': 'Europe/Paris',
    '2': 'Europe/Helsinki',
    '3': 'Europe/Moscow',
    '3.5': 'Asia/Tehran',
    '4': 'Asia/Baku',
    '4.5': 'Asia/Kabul',
    '5': 'Asia/Karachi',
    '5.5': 'Asia/Calcutta',
    '6': 'Asia/Colombo',
    '7': 'Asia/Bangkok',
    '8': 'Asia/Singapore',
    '9': 'Asia/Tokyo',
    '9.5': 'Australia/Darwin',
    '10': 'Pacific/Guam',
    '11': 'Asia/Magadan',
    '12': 'Asia/Kamchatka'
  };
    // @ts-ignore
    this.currentTimezone = timezones[-offset / 60];
    const timeZone = localStorage.getItem('_timeZones');
    if (timeZone && timeZone.length > 0 ) {
      this.timeZoneList = JSON.parse(timeZone);
    } else {
      this.baseRequestService.doRequest('/api/cyberutils/dummy/getSupportedTimeZones', 'post', {})
        .subscribe((result: any) => {
        this.timeZoneList = result;
      });
    }
  }
  addCompany(name: any): void {
    this.addCompanyChange.next(name);
  }
  addSite(ele: any, name: any): void {
    ele.action = name.action;
    this.addSiteChange.next(ele);
  }

  getPatchingStatus(companyId?: any): void {
    this.patchingStatus = false;
    this.isEulaAccepted = false;
    const data: any = {};
    if (companyId) { data.companyId = companyId; }
    this.baseRequestService.doRequest('/api/cyberpatching/getPatchingStatus', 'post', data)
      .subscribe((result: any) => {
        if (result) {
          this.patchingStatus = result.patchingStatus;
          this.isEulaAccepted = result.isEulaAccepted;
        } else {
          this.toast.sToast('error', result[1]);
        }
    });
  }

  async sortFn(arr: any, key?: string): Promise<any> {
    if (key) {
      return await arr.sort((a: any, b: any) => {
        const c = a[key]; const d = b[key];
        if (c < d) { return -1; } else if (c > d) { return 1; } else { return 0; }
      });
    }
    return await arr.sort((c: any, d: any) => {
      if (c < d) { return -1; } else if (c > d) { return 1; } else { return 0; }
    });
  }

  // t = level, a = diameter, e = length;
  calculateHCP(t: any=0, a: any=0, e: any=0): any {
        let h=0; let M=0; let o=0; let l=0; let r=0; let n=0; let P=0; let c=0; let f=0; let u=0; let I=0; let i=0; let d=0;
        // eslint-disable-next-line max-len
        return h=0,M=0,c=0,P=0,r=0,l=0,n=0,h=a/2,r=Math.PI*h*h,M=h-t,o=Math.sqrt(h*h-M*M),P=M*o,l=180/Math.PI*2*Math.atan(o/M),l<=0&&(l=360+l,P*=-1),n=(360-l)/360*r,c=r-P-n,f=Math.PI*h*h*e,u=c*e,t/a>.5&&(n=l/360*r,u=(n+P)*e),I=f-u,i=u/f*100,i*=1e4,i=Math.floor(i),i/=1e4,d=100-i,d*=1e4,d=Math.floor(d),d/=1e4,i.toFixed(0)
    }
}
