import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-petrol-tank',
  templateUrl: './petrol-tank.component.html',
  styleUrls: ['./petrol-tank.component.scss']
})
export class PetrolTankComponent implements OnInit, AfterViewInit {

    @Input() level: any;
    @Input() name: any;
    @Input() product: any;

    constructor() {

    }
      ngAfterViewInit(): void {
          const cnt = document.getElementById(this.name);
          const water = document.getElementById(this.product);
          cnt.innerHTML = this.level;
          water.style.transform='translate(0'+','+(100 - +this.level)+'%)';
      }

    ngOnInit(): void {
    }

}
